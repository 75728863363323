import React from 'react';
import { NextPage,NextPageContext } from 'next';
import HomeV2 from 'components/Home/HomeV2/HomeV2';
import BaseStyle from 'utils/BaseStyle';
import { DEFAULT_HOMEPAGE_CONFIG } from 'constants/configs';
import { HomePageConfig } from 'types/HomePageConfig';
interface Props {
  config: HomePageConfig
}
export const getStaticProps = async (context: NextPageContext) => {
  context.res?.setHeader('Cache-Control', 'public, must-revalidate');
  return { props: { config: DEFAULT_HOMEPAGE_CONFIG } };
};
const HomePage: NextPage<Props> = (props) => {
  return (
    <>
      <style>{BaseStyle}</style>
      <HomeV2 config={props.config} />
    </>
  );
};

export default HomePage;

